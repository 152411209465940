import { JsonService } from '../service/json/jsonService';

// nextJs run time variables

const getRuntimeEnvVars = () => {
  if (!process) {
    throw new Error('getRuntimeEnvVars should be executed on the server.');
  }

  return {
    HYGRAPH_STALE_IF_ERROR_DURATION: process.env.HYGRAPH_STALE_IF_ERROR_DURATION ?? '90000',
    HYGRAPH_STALE_WHILE_REVALIDATE_DURATION:
      process.env.HYGRAPH_STALE_WHILE_REVALIDATE_DURATION ?? '7200',
    RECIPE_ENV: (process.env.RECIPE_ENV ?? 'PROD').toLowerCase(),
    API_URL: process.env.API_URL ?? 'http://oetker-co-uk.oet-stg-k8s-akqa.a.oediv.io/api',
    API_KEY: process.env.API_KEY || '',
    API_URL_BASE: process.env.API_URL || null,
    LOCALIZED_DOMAINS: JsonService.parseJSON(process.env.LOCALIZED_DOMAINS || '[]', []),
    WEB_VERSION: process.env.WEB_VERSION || 0,
    REDIS_CACHE_ENABLED: process.env.REDIS_CACHE_ENABLED === 'true',
    REDIS_URL: process.env.REDIS_URL,
    REDIS_CACHE_TTL: parseInt(process.env.REDIS_CACHE_TTL ?? '7200', 10),
    REDIS_TLS_PEM: process.env.REDIS_TLS_PEM,
    REDIRECTS_ENABLED: process.env.REDIRECTS_ENABLED === 'true',
    HYGRAPH_SECURE_TOKEN:
      process.env.CMS_STAGE === 'DRAFT'
        ? process.env.HYGRAPH_SECURE_TOKEN_DRAFT
        : process.env.HYGRAPH_SECURE_TOKEN_PUBLISHED,
    VERBOSE_LOGGING: process.env.VERBOSE === 'true',
  };
};

// nextJs build time variables
const getBuildtimeEnvVars = () => {
  return {
    GRAPHCMS_ENDPOINT: process.env.GRAPHCMS_ENDPOINT,
    NEXT_PUBLIC_ALGOLIA_APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    NEXT_PUBLIC_ALGOLIA_API_KEY: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
    NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
    NODE_ENV: process.env.NODE_ENV,
    RECIPE_ES_HOST: process.env.RECIPE_ES_HOST || '',
    USERCENTRICS_IDS: process.env.USERCENTRICS_IDS || null,
    NEXT_PUBLIC_HAPPY_CART_CONFIG: process.env.NEXT_PUBLIC_HAPPY_CART_CONFIG || null,
  };
};

export const Environment = {
  getRuntimeEnvVars,
  getBuildtimeEnvVars,
};
